import React, { useCallback, useEffect } from "react";
import gql from "graphql-tag";
import { Layout, Icon, Badge, Avatar, LocaleProvider, DatePicker, Spin, Form, Input, Tooltip, Button, message, Row, Col, Select, Tag } from 'antd';
import { useMutation } from "@apollo/react-hooks";
import { Alert } from '../../Layout/Files';
import { ens } from '../../Layout/TemplateHeader';
import { strings } from '../../Layout/Strings';

const { TextArea } = Input;
const { Option } = Select;
const UPDATE_USER = gql`
      mutation UPDATEUSER($_id:ID,$user_name: String,$sur_name: String,$email: String,$password: String,$employee_number: String,$card_number: String,$position: String,$user_type: String,$area:String,$lang:String,$group:String) {   
            updateUser(_id:$_id,user_name: $user_name,sur_name: $sur_name,email: $email,password: $password,employee_number: $employee_number,card_number: $card_number,user_type: $user_type,position:$position,lang:$lang,area:$area,group:$group)     {
                  _id,
                  user_name,
                  sur_name,
                  email,
                  password,
                  employee_number,
                  card_number,
                  position,
                  user_type,
                  area,
                  group,
                  msg,
                  status,         
            }
      }`
export const Update = (props) => {
      console.log(props);
      const [user_name, setuser_name] = React.useState('');
      const [sur_name, setsur_name] = React.useState('');
      const [email, setemail] = React.useState('');
      const [password, setpassword] = React.useState('');
      const [employee_number, setemployee_number] = React.useState('');
      const [card_number, setcard_number] = React.useState('');
      const [position, setposition] = React.useState('');
      const [area, setarea] = React.useState('');
      const [user_type, setuser_type] = React.useState('');
      const [group, setGroup] = React.useState('');
      const [updateUser] = useMutation(UPDATE_USER, {});
      const [spin, setspin] = React.useState(0);
      useEffect(() => {
            console.log(props);
            setuser_name(props.data.user_name);
            setsur_name(props.data.sur_name);
            setemail(props.data.email);
            setemployee_number(props.data.employee_number);
            setcard_number(props.data.card_number);
            setposition(props.data.position)
            setarea(props.data.area);
            setuser_type(props.data.user_type);
            setpassword(props.data.password)
      }, [props.data]);
      
      const onChange = value => {
            setuser_type(value);
            if(value === "FIXED"){
                  setGroup("T3_GEPP_Acceso");
            } else if(value === "POOL") {
                  setGroup("T3_GEPP_SinAcceso");
            }
      };
      const oncancel = () => {
            setuser_name('');
            props.cancelmodel(false);
      }
      const update_user = () => {
            setspin(1);
            if (user_name !== '' && sur_name !== '' && email !== '' && user_type !== '' 
            && employee_number !== '' && card_number !== '' && position !== '' && area !== '' && password !== '') {
                  
                  updateUser({
                        variables: {
                              _id: props.data._id,
                              user_name: user_name,
                              sur_name: sur_name,
                              email: email,
                              password: password,
                              employee_number: employee_number,
                              card_number: card_number,
                              position: position,
                              area: area,
                              user_type: user_type,
                              group:group,
                              lang: ens,
                        },
                  }).then(result => {
                        setspin(0);
                        console.log(result.data);
                        props.updateuser(result.data);
                  });
                        
            } else {
                  setspin(0);
                  Alert({ msg: 'Please fill all the details.', status: 'failed' });
            }
      }
      strings.setLanguage(ens);
      return (
            <div className="gutter-example">
                  <Spin tip="Loading..." className={spin === 0 ? 'd-none' : ''}  > </Spin>                  
                  <Row gutter={16}>
                        <Col className="gutter-row" span={12}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Badge status="processing" text="" />
                                    <Input placeholder={strings.un} value={user_name} onChange={(e)=>setuser_name(e.target.value)} required = {true} />
                              </div>
                        </Col>
                        <Col span={1} style={{paddingLeft:12, paddingRight:0}}>
                              <Badge status="processing" text=""/>
                        </Col>
                        <Col className="gutter-row" span={10}>
                              <div style={{ marginBottom: 16 }}>
                                    <Input placeholder={strings.sn} value={sur_name} onChange={(e)=>setsur_name(e.target.value)} required = {true}/>
                              </div>
                        </Col>
                  </Row>
                  <Row gutter={16}>
                        <Col className="gutter-row" span={16}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Badge status="processing" text=""/>
                                    <Input placeholder={strings.email} value={email} onChange={(e)=>setemail(e.target.value)} required = {true}/>
                              </div>
                        </Col>
                        <Col className="gutter-row" span={8}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Badge status="processing" text="" style={{marginRight:5}}/>
                                    <Select defaultValue={strings.ut} value={user_type} style={{ width: '16.3em' }} onChange={onChange}>
                                          <Option value="">Select User type</Option>
                                          <Option value="FIXED">Fixed</Option>
                                          <Option value="POOL">Pool</Option>
                                    </Select>
                              </div>
                        </Col>
                  </Row>
                        
                  <Row gutter={16}>
                        <Col className="gutter-row" span={11}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Badge status="processing" text=""/>
                                    <Input placeholder={strings.en} value={employee_number} onChange={(e)=>setemployee_number(e.target.value)} required = {true}/>
                              </div>
                        </Col>
                        <Col span={1} style={{paddingLeft:12, paddingRight:0}}>
                              <Badge status="processing" text=""/>
                        </Col>
                        <Col className="gutter-row" span={11}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Input placeholder={strings.cn} value={card_number} onChange={(e)=>setcard_number(e.target.value)} required = {true}/>
                              </div>
                        </Col>
                  </Row>
                  <Row gutter={16}>
                        <Col className="gutter-row" span={11}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Badge status="processing" text="" />
                                    <Input placeholder={strings.position} value={position} onChange={(e)=>setposition(e.target.value)} required = {true}/>
                              </div>
                        </Col>
                        <Col span={1} style={{paddingLeft:12, paddingRight:0}}>
                              <Badge status="processing" text=""/>
                        </Col>
                        <Col className="gutter-row" span={12}>
                              <div style={{ marginBottom: 16 }}>
                                    <TextArea rows={1} placeholder={strings.area} value={area} onChange={(e)=>setarea(e.target.value)} required = {true}/>
                              </div>
                        </Col>
                  </Row>
                  <Row gutter={16}>
                        <Col className="gutter-row" span={24}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Badge status="processing" text="" />
                                    <Input.Password placeholder="PASSWORD" onChange={(e)=>{setpassword(e.target.value)}} />
                              </div>
                        </Col>
                  </Row>
                  <Row>
                        <Col>
                              <Button key="back" onClick={oncancel}> Cancel </Button>
                              <Button className="float-right" key="submit" type="primary" onClick={update_user}> Submit </Button>
                        </Col>
                  </Row>
            </div>
      );
};
