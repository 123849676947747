import React from 'react';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import { Layout, Menu, Icon, Typography, Badge, Alert, notification, Row, Col } from 'antd';
import { ens } from './TemplateHeader';
import { strings } from './Strings';
import gepp_logo from "../../gepp.png";
import 'antd/dist/antd.css';
import '../../scss/template.scss';
import { COUNT } from '../../graphql/Report';
import { Subscription } from 'react-apollo';
import gql from 'graphql-tag';
import { client } from '../../apollo';
const { Text } = Typography;
const { Sider } = Layout;
const { SubMenu } = Menu;
const ANYTHING_SUBSCRIPTION = gql`
    subscription{
      acceptedMessage{
        count
        user_name
        slot
        reservation
        date
      }
    }
`;
const DontReadTheComments = () => (
  <Subscription subscription={ANYTHING_SUBSCRIPTION}>
    {({ data: { acceptedMessage }, loading }) => (
      <div>
        <Alert message="done" type="warning" closable />
        <h4>New comment: {!loading && acceptedMessage[0].count}</h4>
      </div>
    )}
  </Subscription>
);
const openNotificationWithIcon = (type, name, slot, date, reservation) => {
  notification[type]({
    message: 'REPORT',
    duration: 0,
    description:
      <div>
        <Row> <Col span={12}> USER NAME </Col> <Col span={12}> {name} </Col> </Row>
        <Row> <Col span={12}> SLOT </Col> <Col span={12}> {slot} </Col> </Row>
        <Row> <Col span={12}> DATE </Col> <Col span={12}> {date} </Col> </Row>
        <Row> <Col span={12}> RESERVATION </Col> <Col span={12}> {reservation} </Col> </Row>
      </div>
    ,
  });
};
class TemplateSider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      en: 1
    }
    console.log(this.props.location.pathname);
  }
  componentWillMount() {
    DontReadTheComments();
  }
  render() {
    strings.setLanguage(ens);
    return (
      <Sider style={{ overflow: 'auto', height: '100vh', left: 0, }} className="d-none d-sm-none d-lg-block d-xl-block d-xxl-block template_sider" trigger={null} collapsible collapsed={this.props.update_collapsed}>
        <div className="logo">
          <img src={gepp_logo} alt={'GEPP'} width="150" height="75" />
        </div>
        <Menu theme="dark" selectedKeys={[this.props.location.pathname]} mode="inline">
          <Menu.Item key="/admin-dashboard">
            <Icon type="dashboard" />
            <span>{strings.DASHBOARD}</span>
            <Link to="/admin-dashboard" />
          </Menu.Item>
          <Menu.Item key="/admin-user">
            <Icon type="user" />
            <span>{strings.USER}</span>
            <Link to="/admin-user" />
          </Menu.Item>
          <Menu.Item key="/admin-slot">
            <Icon type="desktop" />
            <span className={strings.SLOT ==='ESTACIÓN DE TRABAJO'? "slotSpanish": ""}>{strings.SLOT}</span>
            <Link to="/admin-slot" />
          </Menu.Item>
          <Menu.Item key="/admin-booking">
            <Icon type="book" />
            <span>{strings.BOOKING}</span>
            <Link to="/admin-booking" />
          </Menu.Item>
          <Menu.Item key="/admin-report">
            <Icon type="bell" />
            <span> {strings.REPORT}</span>
            <Subscription subscription={ANYTHING_SUBSCRIPTION}>
              {({ data, loading }) => {
                console.log("Subscription from component/Layout/TemplateSider.js",data,loading);
                if (data !== undefined) {
                  openNotificationWithIcon('info', data.acceptedMessage[0].user_name, data.acceptedMessage[0].slot, data.acceptedMessage[0].date, data.acceptedMessage[0].reservation);
                  return <Badge className="ml-4" count={!loading && data.acceptedMessage[0].count} />;
                } else {
                  return <span></span>
                }
              }}
            </Subscription>
            <Link to="/admin-report" />
          </Menu.Item>
          {/* <Menu.Item key="/admin-penality">
            <Icon type="alert" />
            <span>{strings.PENALTY}</span>
            <Link to="/admin-penality" />
          </Menu.Item> */}
          <Menu.Item key="/admin-holiday">
            <Icon type="calendar" />
            <span>{strings.HOLIDAY}</span>
            <Link to="/admin-holiday" />
          </Menu.Item>
          <Menu.Item key="/admin-static">
            <Icon type="highlight" />
            <span>{strings.STATIC}</span>
            <Link to="/admin-static" />
          </Menu.Item>
          <Menu.Item key="/admin-archive">
            <Icon type="rest" />
            <span>{strings.ARCHIVE}</span>
            <Link to="/admin-archive" />
          </Menu.Item>
          <Menu.Item key="/admin-admin">
            <Icon type="user" />
            <span>{strings.ADMIN}</span>
            <Link to="/admin-admin" />
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}
export default withRouter(TemplateSider);
