import React from "react";
import { Icon, Tooltip, Input, Button, Collapse, Select, Popconfirm, Row, Col, Table, Modal, Tag, LocaleProvider, DatePicker } from 'antd';
import { Uploads } from './Uploads';
import { Update } from './Update';
import { Alert } from '../../Layout/Files';
import 'antd/dist/antd.css';
import '../../../scss/template.scss';
import '../../../scss/Dashboard.scss';
import '../../../scss/User.scss';
import { ens } from '../../Layout/TemplateHeader';
import { GET_USER, FIND_USER, DELETE_USER, DOWNLOAD_USER,DOWNLOAD_MONTHLY_USER, SEARCH_USER , USER_DETAIL_CENTAUR} from '../../../graphql/User';
import { client, URI } from "../../../graphql/Client";
import { strings } from '../../Layout/Strings';
import moment from "moment";
import fr_FR from 'antd/es/locale-provider/en_US';
const { MonthPicker } = DatePicker;
const Panel = Collapse.Panel;
const { Option } = Select;
class User_Mange extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  _id: '',
                  reportVisible: false,
                  user_model_visible: false,
                  user_name: '',
                  sur_name: '',
                  email: '',
                  password: '',
                  user_type: '',
                  dataSource: [],
                  data: {},
                  loading: false,
                  pagination: {
                        pageSize: 10,
                        current: 1,
                        total: 0,
                        simple: true,
                  },
                  openPanel: "0",
                  s_email: '', s_en: '', s_name: '', s_ut: '', s_sname: "",
            };
      }
      componentDidMount() {
            this.fetch_User();
            this.download();
            // this.reportDownload();
      }
      // fecth user
      fetch_User = async (visible) => {
            this.setState({loading:false});
            await client.query({
                  query: GET_USER,
                  variables: { limit: this.state.pagination.pageSize, page: this.state.pagination.current, lang: ens },
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  this.download();
                  // this.reportDownload();
                  // console.log(result.data.users);
                  const pagination = { ...this.state.pagination };
                  pagination.total = result.data.users.totalDocs;
                  // console.log(pagination);
                  this.setState({ pagination, loading: false, user_model_visible: visible, reportVisible: visible, dataSource: result.data.users.docs });
            });
            await client.query({
                  query: USER_DETAIL_CENTAUR,                  
                  fetchPolicy: 'no-cache',
            }).then(result=>{
                  console.trace(result);
            })
      }
      fetch_Users = async () => {
            await client.query({
                  query: GET_USER,
                  variables: { limit: this.state.pagination.pageSize, page: this.state.pagination.current, lang: ens },
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  this.download();
                  // this.reportDownload();
                  const pagination = { ...this.state.pagination };
                  pagination.total = result.data.users.totalDocs;
                  // console.log(pagination);
                  this.setState({ pagination, loading: false, dataSource: result.data.users.docs });
            });
      }
      handleTableChange = pagination => {
            const pager = { ...pagination };
            pager.current = pagination.current;
            client.query({
                  query: GET_USER,
                  variables: { limit: pager.pageSize, page: pager.current },
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  console.log(result.data);
                  const pagination = { ...this.state.pagination };
                  pagination.total = result.data.users.totalDocs;
                  pagination.current = result.data.users.page;
                  console.log(pagination);
                  this.setState({ pagination, loading: false, dataSource: result.data.users.docs });
            });
      };
      download = async () => {
            console.log("Excel");
            await client.query({
                  query: DOWNLOAD_USER,
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  console.log("download");
            });            
      }
      // reportDownload = async () => {
      //       await client.query({
      //             query: DOWNLOAD_MONTHLY_USER,
      //             fetchPolicy: 'no-cache',
      //       }).then(result => {
      //             console.log("DOWNLOAD_MONTHLY_USER");
      //       });
      // }
      onMonth = (date, dateString) => {
            console.log(dateString);
            if (dateString.length === 0) {
                  // this.setState({ d_book: "ok" });
            }
            client.query({
                  query: DOWNLOAD_MONTHLY_USER,
                  variables: { lang: ens, date: dateString },
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  console.log(result.data);
                  // Alert(result.data);
                  // this.setState({ d_book: "not ok" });
            });
      }
      // update user
      updateUser = async (data) => {
            console.log(data);
            this.setState({ reportVisible: 0, });
            this.fetch_User(false);
            Alert(data.updateUser);
      }
      // add user
      addUser = async (data) => {
            console.log(data.addUser);
            this.setState({ user_model_visible: 0, });
            this.fetch_User(false);
            Alert(data.addUser);
      }
      // delete a record
      reportDelete = async (_id, type) => {
            console.log(_id);
            if (type === "VP" || type === "DIRECTOR" || type === "GERENTE") {
                  Alert({ msg: "Please convert the (NORMAL) user_type", status: 'failed' });
                  return 0;
            } else {
                  await client.mutate({
                        mutation: DELETE_USER,
                        variables: { _id: _id, lang: ens },
                  }).then(result => {
                        Alert(result.data.deleteUser);
                  });
                  this.fetch_User();
            }
      };
      // user data visible in update process
      userVisible = async (_id) => {
            console.log(_id);
            await client.query({
                  query: FIND_USER,
                  variables: { _id: _id, lang: ens },
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  console.log(result.data);
                  this.setState({ data: result.data.user, reportVisible: true });
            });
      }
      res_search = value => {
            const { s_name, s_en, s_ut, s_sname, s_email } = this.state;
            var data = {};
            console.log(s_name);
            console.log(s_ut);
            console.log(s_email);
            console.log(s_en);
            console.log(s_sname);
            if (s_email !== '') {
                  data['email'] = s_email;
            }
            if (s_name !== '') {
                  data['user_name'] = s_name;
            }
            if (s_sname !== '') {
                  data['sur_name'] = s_sname;
            }
            if (s_ut !== '') {
                  data['user_type'] = s_ut;
            }
            if(s_en !== '') {
                  data["employee_number"]= s_en;
            }
            console.log(data);
            client.query({
                  query: SEARCH_USER,
                  variables: { lang: ens, data: data },
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  console.log(result.data);
                  if (result.data.search_user.length !== 0) {
                        this.setState({ loading: false, dataSource: result.data.search_user });
                  } else {
                        console.log({ msg: "INVALID DATA", status: 'failed' });
                  }
            });
      }
      render() {
            strings.setLanguage(ens);
            const columns = [
                  {
                        title: <span>{strings.un}</span>,
                        dataIndex: '',
                        key: '',
                        width: 20,
                        align: 'center',
                        // defaultSortOrder: 'descend',
                        // sorter: (a, b) => a.user_name.length - b.user_name.length,
                        // sortDirections: ['descend', 'ascend'],
                        render: (text, record) => {
                              return <span title="NAME">{record.user_name}</span>;
                        }
                  },
                  {
                        title: <span>{strings.email}</span>,
                        key: 'email',
                        width: 25,
                        align: 'center',
                        render: (text, record) => {
                              return <span title="EMAIL">{record.email}</span>;
                        }
                  },
                  {
                        title: <span>{strings.ut}</span>,
                        dataIndex: '',
                        key: 'user_type',
                        width: 10,
                        align: 'center',
                        render: (text, record) => {
                              return <span title="USER TYPE">{record.user_type}</span>;
                        }
                  },
                  {
                        title: <span>{strings.en}</span>,
                        dataIndex: '',
                        key: 'employee_number',
                        width: 10,
                        align: 'center',
                        render: (text, record) => {
                              return <span title="Employee Number">{record.employee_number}</span>;
                        }
                  },
                  // {
                  //       title: <span>{strings.position}</span>,
                  //       dataIndex: '',
                  //       key: 'position',
                  //       width: 10,
                  //       align: 'center',
                  //       render: (text, record) => {
                  //             return <span title="POSITION">{record.position} </span>;
                  //       }
                  // },
                  // {
                  //       title: <span>{strings.area}</span>,
                  //       dataIndex: '',
                  //       key: 'area',
                  //       width: 10,
                  //       align: 'center',
                  //       render: (text, record) => {
                  //             return <span title="AREA">{record.area} </span>;
                  //       }
                  // },
                  {
                        title: '...',
                        dataIndex: '...',
                        width: 5,
                        align: 'center',
                        render: (text, record) => {
                              if (this.state.dataSource.length >= 1) {
                                    return (
                                          <div>
                                                <Tooltip placement="left" title="Edit">
                                                      <Icon type="edit" tabIndex="-1" onClick={() => this.userVisible(record._id)} />
                                                </Tooltip>
                                                <Tooltip placement="topRight" title="Delete">
                                                      <Popconfirm title="Sure to delete?" onConfirm={() => this.reportDelete(record._id, record.user_type)}>
                                                            <Icon type="delete" />
                                                      </Popconfirm>
                                                </Tooltip>
                                          </div>
                                    );
                              }
                        }
                  },
            ];
            return (
                  <div>
                        <Modal
                              title="UPDATE USER"
                              style={{ top: 20 }}
                              visible={this.state.reportVisible}
                              onCancel={() => this.setState({ reportVisible: 0, data: '' })}
                              footer={[]} >
                              <Update updateuser={this.updateUser} data={this.state.data} cancelmodel={(data) => { this.setState({ reportVisible: data }) }} />
                        </Modal>
                        {/* add user in model */}
                        <Modal
                              title="ADD USER"
                              style={{ top: 20 }}
                              visible={this.state.user_model_visible}
                              onCancel={() => this.setState({ user_model_visible: 0, data: '' })}
                              footer={[]} >
                              <Uploads adduser={this.addUser} cancelmodel={(data) => { console.log(data); this.setState({ user_model_visible: 0, data: '' }); }} />
                        </Modal>
                        <div data-toggle="tooltip" title="ADD USER!" data-placement="left" className="user_add_position ">
                              <Tooltip placement="left" title="Add User">
                              <Icon type="user-add" className="user_add icon_size" onClick={() => this.setState({ user_model_visible: true })} />
                              </Tooltip>
                        </div>
                        <Row className="mb-4">
                              <Collapse activeKey={this.state.openPanel} destroyInactivePanel={true}>
                                    <Panel header={
                                          <Row>
                                                <Col lg={24}>
                                                      <Row>
                                                            <Col lg={12}>
                                                                  <Input placeholder="Email" onChange={(e) => { this.setState({ s_email: e.target.value }) }} />
                                                            </Col>
                                                            <Col lg={12}>
                                                                  <Button className="ml-4" type="primary" onClick={this.res_search} >Search</Button>
                                                                  <Button className="ml-4" type="dashed" onClick={() => { this.state.openPanel === '1' ? this.setState({ openPanel: '0' }) : this.setState({ openPanel: '1' }) }} >Advanced Search</Button>
                                                                  <Button className="ml-4 float-right" type="primary" onClick={this.fetch_Users} >All Users</Button>
                                                            </Col>
                                                      </Row>
                                                </Col>
                                          </Row>
                                    } key="1">
                                          <Row gutter={12}>
                                                <Col lg={2}>
                                                      <Input placeholder="Sur Name" onChange={(e) => { this.setState({ s_sname: e.target.value }) }} />
                                                </Col>
                                                <Col lg={6}>
                                                      <Input placeholder="Name" onChange={(e) => { this.setState({ s_name: e.target.value }) }} />
                                                </Col>
                                                <Col lg={8}>
                                                      <Input placeholder="Employee Number" onChange={(e) => { this.setState({ s_en: e.target.value }) }} />
                                                </Col>
                                                <Col lg={8}>
                                                      <Select placeholder="User Type" defaultValue="" style={{ width: "-webkit-fill-available" }} onChange={(value) => { this.setState({ s_ut: value }) }}>
                                                            <Option value="">select user type</Option>
                                                            <Option value="FIXED">Fixed</Option>
                                                            <Option value="POOL">Pool</Option>
                                                      </Select>
                                                </Col>
                                          </Row>
                                         
                                    </Panel>
                              </Collapse>
                        </Row>
                        <div id="no-more-tables">
                              <Table expandedRowRender={record =>
                                    <div className="expand">
                                          <Row>
                                                <Col sm={24} lg={12}>
                                                      <Row >
                                                            <Col sm={12} lg={12}>
                                                                  <span>{strings.sn}</span>
                                                            </Col>
                                                            <Col sm={12} lg={12}>
                                                                  : <Tag color="blue" >{record.sur_name}</Tag>
                                                            </Col>
                                                      </Row>
                                                      <Row className="py-3">
                                                            <Col sm={12} lg={12}>
                                                                  <span>{strings.position}</span>
                                                            </Col>
                                                            <Col sm={12} lg={12}>
                                                                  : <Tag color="green" >{record.position}</Tag>
                                                            </Col>
                                                      </Row>
                                                      <Row className="py-3">
                                                            <Col sm={12} lg={12}>
                                                                  <span>{strings.area}</span>
                                                            </Col>
                                                            <Col sm={12} lg={12}>
                                                                  : <Tag color="green" >{record.area}</Tag>
                                                            </Col>
                                                      </Row>
                                                      {/* <Row>
                                                            <Col sm={12} lg={12}>
                                                                  <span>VACATION PERIOD :</span>
                                                            </Col>
                                                            <Col sm={12} lg={12}>
                                                                  :<Tag>{moment(record.start_date).format('YYYY-MM-DD')}</Tag>
                                                                  <Tag>~</Tag>
                                                                  <Tag>{moment(record.end_date).format('YYYY-MM-DD')}</Tag>
                                                            </Col>
                                                      </Row> */}
                                                </Col>
                                          </Row>
                                    </div>}
                                    dataSource={this.state.dataSource}
                                    columns={columns}
                                    bordered
                                    loading={this.state.loading}
                                    footer={() =>
                                                { return (
                                                <>
                                                      <a href={URI + "/document/user.xlsx"} download>
                                                            <Button type="primary" shape="round" icon="download" >
                                                                  Download
                                                            </Button>
                                                      </a>
                                                      <a className="ml-5" href={URI + "/document/monthlyUserReport.xlsx"} download>
                                                            <Button type="primary" shape="round" icon="download" >
                                                                  Download Monthly User Report
                                                            </Button>
                                                      </a>
                                                      <LocaleProvider locale={fr_FR}>
                                                            <MonthPicker onChange={this.onMonth} className="px-3" placeholder="Select Month" />
                                                      </LocaleProvider>
                                                </>)
                                                }
                                          }
                                    pagination={this.state.pagination}
                                    onChange={this.handleTableChange}
                              />
                        </div>
                  </div>
            );
      }
}
export default User_Mange;
