import gql from "graphql-tag";
export const GET_USER = gql` 
query Getbooking($limit: Int!, $page: Int) {
    users(limit: $limit, page: $page) {
            _id,
            user_name,
            sur_name,
            email,
            password,
            user_type,
            card_number,
            employee_number,
            area,
            position,
            msg,
            totalDocs,
            totalPages,
            docs,
            page,
      }
}
`;

export const FIND_USER = gql`
      query finduser($_id: ID,$lang:String) {
            user(_id: $_id,lang:$lang) {
                  _id,
                  user_name,
                  sur_name,
                  email,
                  password,
                  user_type,
                  card_number,
                  employee_number,
                  area,
                  position,
                  msg,
            }
      }
`;


export const DELETE_USER = gql`
    mutation DELETEUSER($_id: ID,$lang:String)  {
      deleteUser(_id:$_id,lang:$lang){
        msg,
        status         
    }
}`;

export const DOWNLOAD_USER = gql`
{
    downloaduser{ 

      _id
      
    }
  }
`;

export const DOWNLOAD_MONTHLY_USER = gql`
   query DOWNLOADMONTHLYUSER($lang: String, $date: String) {
      monthlyUserReport(lang: $lang, date: $date) { 
           status,
           msg,
      }
  }
`;

export const SEARCH_USER = gql`
      query findusers($data: JSON,$lang:String) {
            search_user(data: $data,lang:$lang) {
                  _id,
                  user_name,
                  sur_name,
                  email,
                  user_type,
                  card_number,
                  employee_number,
                  area,
                  position,
                  msg,
            }
      }
`;


export const USER_EMAIL_QUERY = gql`
      query findusers($data: JSON,$lang:String) {
            search_user_email(data: $data,lang:$lang) {
                  _id,
                  user_name,
                  sur_name,
                  email,
                  user_type,
                  card_number,
                  employee_number,
                  area,
                  position,
                  msg,
            }
      }
`;

export const USER_DETAIL_CENTAUR = gql`
{      
      getUsersCentaur {
            type,
            action,
            card_number,
            employee_number,
            group
      }
}
`;